body {
    font-family: 'Helvetica';
  }
  h1,
  h2,
  h3 {
    margin: 0.5rem 0;
  }
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  h3 {
    font-size: 1rem;
  }
  a {
    text-decoration: none;
  }
.row {
    display: flex;
    justify-content: space-between;
  }
  .row.center {
    align-items: center;
  }
  .col-1 {
    flex: 1;
  }
  .col-2 {
    flex: 2;
  }
  .block {
    background-color: #fff;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
  }
  .text-right {
    text-align: right;
  }
  img.small {
    max-height: 8rem;
  }

.boldText {
  font-weight: bold;
}  
  button {
    font-size: 0.8rem;
    padding: 0.2rem;
    margin: 0.1rem;
    border: 0.01rem #404040 solid;
    background-color: #f0c040;
    width: 100%;
    cursor: pointer;
  }
  button.add {
    background-color: #000;
    color: #fff;
    width: 2rem;
  }
  button.remove {
    background-color: #fff;
    width: 2rem;
  }
  button.badge {
    background-color: #f04040;
    border: none;
    color: #ffffff;
    width: 1.5rem;
  }


  .nav-tabs .nav-link {
    background-color: transparent !important;
    color: #444 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    font-size: 0.875rem;
  }
  
  .nav-tabs .nav-link.active {
    border-bottom: solid 4px #000 !important;
    color: #000 !important;
    font-size: 0.875rem;
    font-weight: 'bold';
  }
  .card {
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    padding: 1.2rem;
    border: 1px #ccc solid;
    box-shadow: 1px 1px 1px 0 rgba(192, 192, 192, 0.3), 0 1px 2px 0 rgba(192, 192, 192, 0.2);
    position: relative;
  }    
    .cardContent {
      padding: 0.4rem 0;
    }

    .cardContent h3 {
        font-weight: 600;
        font-size: 0.9rem;
        margin-bottom: 0;
        letter-spacing: 0.6px;
      }
    .cardContent span {
        font-size: 0.8rem;
        color: #282828;
      }
      .bg {
        background: #000;
      
    height: '100%';
    
    overflow: 'hidden';
      }








      @import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap");

/*   color variables 
$clr-primary: #0e48fe;
$clr-primary-hover: #29e6a7;
$clr-primary-dark: #039d69;
$clr-gray100: #f9fbff;
$clr-gray150: #f4f6fb;
$clr-gray200: #eef1f6;
$clr-gray300: #e1e5ee;
$clr-gray400: #767b91;
$clr-gray500: #4f546c;
$clr-gray600: #2a324b;
$clr-gray700: #161d34;
*/


body {
  font-family: "Noto Sans", sans-serif;
  color: #4f546c;
}

.wrapper {
  margin: 2rem;
  display: grid;
  gap: 1rem;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));

}

.card {
  box-shadow: 0 0 10px 5px #e1e5ee;
  border-radius: 0.2rem;
  display: flex;
  min-width: 14rem;
}

.cardImg {
    width: 14rem;
    height: 10rem;
    object-fit: cover;
}

.cardBody {
    margin: 1rem;
    flex-grow: 1;
}
.cardTitle {
    font-size: 1rem;
    line-height: 1.4rem;
    margin-bottom: 0.5rem;
}
.cardDescription {
    line-height: 1.2rem;
    font-size: 0.8rem;
}
.cardPrice {
    font-size: 0.9rem;
    margin-top: 0.6rem;
      font-size: 1rem;
      position: relative;
      top: -0.3rem;
      padding-right: 0.1rem;
}

.cardBtn {
    border: none;
    border-top: 1px solid #e1e5ee;
    background-color: transparent;
    font-family: inherit;
    font-size: 0.8rem;
    font-weight: bold;
    color: inherit;
    width: 100%;
    padding-top: 1rem;
    margin-top: 1rem;
    cursor: pointer;
}
.cardBtn:hover {
      color: #0e48fe;
}


@media screen and (max-width: 992px) {
  .wrapper {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));
  }

  .card {
    flex-direction: column;
    text-align: center;
    min-width: 14rem;
  }
  .cardImg {
      width: 100%;
      height: 10rem;
    }
}


@media screen and (max-width: 600px) {
  .wrapper {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));
  }

  .card {
    flex-direction: column;
    text-align: center;
    min-width: 14rem;
  }
  .cardImg {
      width: 100%;
      height: 10rem;
    }
  
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}


.container{
  text-align: center;
  margin: 0 auto;
  width:30%;
  margin-top: 50px;
}


.container1{
  text-align: center;
  margin: 0 auto;
  width:90%;
  margin-top: 50px;
}

.one{
  background:#FFEB3B;
  width:"50%";
  float:left;
}
.two{
  width:"50%";
  background:#8BC34A;
  overflow:hidden;
}

.css-fix {
  white-space: pre-wrap;
}

.blackLink {
  color: #444;
}
.blackLink:hover {
   color: #444
}

.whiteLink {
  color: #fff;
}
.whiteLink:hover {
   color: #fff
}
