@import url(https://fonts.googleapis.com/css2?family=Inter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap);
/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
    transition: -webkit-transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out;
    transition: transform 300ms ease-in-out, -webkit-transform 300ms ease-in-out;
    transition-delay: 100ms;
    -webkit-transform: scale(0);
            transform: scale(0);
    /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open{
    -webkit-transform: scale(1);
            transform: scale(1);
    /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close{
    -webkit-transform: scale(0);
            transform: scale(0);
    /* transform: translateY(-150%); */
}

body {
  background-color: white;
  margin: auto;
  padding: 0px;
  overflow-x: hidden;
  background-repeat: repeat;
  font-family: 'Inter', sans-serif;
  display: grid;
}
.container {
  width: 100%;
	padding-right:15px;
	padding-left:15px;
	margin-right:auto;
	margin-left:auto
}
img {
  max-width: 100%;
  height: auto;
}

.sec__one {
    position: relative;
    width: 100%;
    display: flex;
    padding: 60px 0;
}
.sec__one h1{
  text-align: center;
  display: block;
  margin: 0 auto;
  color: #07a8ff;
  font-family: 'Inter', sans-serif;
}
.navBar .logo img {
  float: left;
  max-height: 120px;
}
/*.is-active {
  color: #ff5800!important;
}*/

.navBar .col-md-3 {
  z-index: 7;
}
ul.main-menu {
  display: inline-flex;
  list-style: none;
  margin: 0px auto 0;
  padding: 0;
  float: right;
  z-index: 7;
}
ul.main-menu>li.menu-item:first-child {
  padding-left: 0;
  margin-left: 0;
}
.menu-item {
  line-height: 23px;
}
li {
  list-style-type: none;
}
.menu-item a {
  font-size: 16px;
  font-weight: 600;
  line-height: 40px;
  position: relative;
  display: block;
  color: #545050;
  margin: 27px 17px;
  text-decoration: none;
  cursor: pointer;
}
.header__middle__logo{
  width: 20%;
  display: inline-block;
  float: left;
}
.header__middle__menus {
  width: 70%;
  display: inline-block;
  float: left;
}
.menu-item.menu__qqqitem a{
  margin: 27px 10px;
}
.header__middle__search {
  width: 7%;
  display: inline-block;
  float: right;
  padding: 36px 3px 27px 3px;
  border-left: 1px solid lavender;
}
.header__middle {
  display: flex;
  width: 100%;
  float: left;
  border-bottom: 1px dashed lavender;
  position: relative;
}
.header__middle__logo > a > h1 {
  color: #07a8ff;
  text-transform: uppercase;
  text-decoration: none;
  margin-top: 33px;
}
/* .active{
  color: #ff1800!important;
} */
.header__middle__menus span.text {
  position: absolute;
  right: 177px;
  bottom: 10px;
  font-size: 14px;
  color: #07a8ff;
}
.sub__menus__arrows{
  position: relative;
}
.sub__menus__arrows svg, .sub__menus__arrows__full svg{
  padding-top: 0px;
  position: absolute;
  top: 13px;
}
.sub__menus {
  position: absolute;
  display: none;
  background: white;
  border: 1px solid lavender;
  width: 227px;
  left: -17px;
  padding: 2px 0 0 0;
  z-index: 1000;
  box-shadow: 0 10px 12px rgb(22 33 39 / 25%);
}

@media (max-width:767px) { 
  .header__middle .active {color: #07a8ff!important;}
}

@media (min-width:992px) { 
  .sub__menus__arrows:hover .sub__menus{
    display: block;
  }
  .sub__menus__arrows__full:hover .sub__menus{
    display: block;
    z-index: 1000000;
  }
}
.menu-item .sub__menus  a {
  margin: 7px 27px;
}
.menu-item:hover > a{
  color: #07a8ff !important;
}
.menu-item .sub__menus li:hover a{
  color: #07a8ff !important;
}

.header__middle__logo img{
  max-width: 207px;
  margin: 17px 0 0 0;
}


@media(max-width:991px){
.header__middle__logo {width: 77%;}
.header__middle__menus {width: 20%;}
.header__middle__logo img {max-width: 197px;margin: 27px 0 0 0;}
.main-nav .menubar__button:hover {background-color: #07a8ff;}
.main-nav .menu-item:hover > a {color: #07a8ff !important}
.main-nav .menubar__button {
  display: block!important;
  float: right;
  background-color: #080808;
  color: #fff;
  padding: 6px 7px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  z-index: 10037;
  margin-right: 25px;
  margin-top: 20px;
}
.main-nav .menubar__button svg{font-size: 27px;}
.main-nav ul.menuq2 {
  display: block!important;
  position: absolute;
  left: 0;
  z-index: 10007;
  background-color: #d8f1ff;
  border-bottom: 3px solid #07a8ff;
  right: 0;
  padding-bottom: 17px;
  padding-top: 57px;
}
.main-nav .menu-item a {margin: 10px 17px;text-align: center;color: #000000;}
.main-nav  ul.main-menu {display: none}
.main-nav .sub__menus__arrows:hover .sub__menus {margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active{display: block!important;margin: 0 auto;left: 0;right: 0;}
.sub__menus__Active2{display: block!important;}
.menus__categorysss{display: none;}
.menus__categorysss2{display: none;}
.menu-item .sub__menus__full a {text-align: left;}

}


.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
#root{ height: 200vh; }
div#b{
  position: -webkit-sticky;
  position: sticky;
  top: -1px;
  font-size: 1.5em;
  align-items: center;
  z-Index:999;
  background: white; 

}
body {
    font-family: 'Helvetica';
  }
  h1,
  h2,
  h3 {
    margin: 0.5rem 0;
  }
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  h3 {
    font-size: 1rem;
  }
  a {
    text-decoration: none;
  }
.row {
    display: flex;
    justify-content: space-between;
  }
  .row.center {
    align-items: center;
  }
  .col-1 {
    flex: 1 1;
  }
  .col-2 {
    flex: 2 1;
  }
  .block {
    background-color: #fff;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
  }
  .text-right {
    text-align: right;
  }
  img.small {
    max-height: 8rem;
  }

.boldText {
  font-weight: bold;
}  
  button {
    font-size: 0.8rem;
    padding: 0.2rem;
    margin: 0.1rem;
    border: 0.01rem #404040 solid;
    background-color: #f0c040;
    width: 100%;
    cursor: pointer;
  }
  button.add {
    background-color: #000;
    color: #fff;
    width: 2rem;
  }
  button.remove {
    background-color: #fff;
    width: 2rem;
  }
  button.badge {
    background-color: #f04040;
    border: none;
    color: #ffffff;
    width: 1.5rem;
  }

  .card {
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    padding: 1.2rem;
    border: 1px #ccc solid;
    box-shadow: 1px 1px 1px 0 rgba(192, 192, 192, 0.3), 0 1px 2px 0 rgba(192, 192, 192, 0.2);
    position: relative;
  }    
    .cardContent {
      padding: 0.4rem 0;
    }

    .cardContent h3 {
        font-weight: 600;
        font-size: 0.9rem;
        margin-bottom: 0;
        letter-spacing: 0.6px;
      }
    .cardContent span {
        font-size: 0.8rem;
        color: #282828;
      }
      .bg {
        background: #000;
      
    height: '100%';
    
    overflow: 'hidden';
      }

/*   color variables 
$clr-primary: #0e48fe;
$clr-primary-hover: #29e6a7;
$clr-primary-dark: #039d69;
$clr-gray100: #f9fbff;
$clr-gray150: #f4f6fb;
$clr-gray200: #eef1f6;
$clr-gray300: #e1e5ee;
$clr-gray400: #767b91;
$clr-gray500: #4f546c;
$clr-gray600: #2a324b;
$clr-gray700: #161d34;
*/


body {
  font-family: "Noto Sans", sans-serif;
  color: #4f546c;
}

.wrapper {
  margin: 2rem;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));

}

.card {
  box-shadow: 0 0 10px 5px #e1e5ee;
  border-radius: 0.2rem;
  display: flex;
  min-width: 14rem;
}

.cardImg {
    width: 14rem;
    height: 10rem;
    object-fit: cover;
}

.cardBody {
    margin: 1rem;
    flex-grow: 1;
}
.cardTitle {
    font-size: 1rem;
    line-height: 1.4rem;
    margin-bottom: 0.5rem;
}
.cardDescription {
    line-height: 1.2rem;
    font-size: 0.8rem;
}
.cardPrice {
    font-size: 0.9rem;
    margin-top: 0.6rem;
      font-size: 1rem;
      position: relative;
      top: -0.3rem;
      padding-right: 0.1rem;
}

.cardBtn {
    border: none;
    border-top: 1px solid #e1e5ee;
    background-color: transparent;
    font-family: inherit;
    font-size: 0.8rem;
    font-weight: bold;
    color: inherit;
    width: 100%;
    padding-top: 1rem;
    margin-top: 1rem;
    cursor: pointer;
}
.cardBtn:hover {
      color: #0e48fe;
}


@media screen and (max-width: 992px) {
  .wrapper {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));
  }

  .card {
    flex-direction: column;
    text-align: center;
    min-width: 14rem;
  }
  .cardImg {
      width: 100%;
      height: 10rem;
    }
}


@media screen and (max-width: 600px) {
  .wrapper {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));
  }

  .card {
    flex-direction: column;
    text-align: center;
    min-width: 14rem;
  }
  .cardImg {
      width: 100%;
      height: 10rem;
    }
  
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}


.container{
  text-align: center;
  margin: 0 auto;
  width:30%;
  margin-top: 50px;
}


.container1{
  text-align: center;
  margin: 0 auto;
  width:90%;
  margin-top: 50px;
}

.one{
  background:#FFEB3B;
  width:"50%";
  float:left;
}
.two{
  width:"50%";
  background:#8BC34A;
  overflow:hidden;
}

.css-fix {
  white-space: pre-wrap;
}
body {
    font-family: 'Helvetica';
  }
  h1,
  h2,
  h3 {
    margin: 0.5rem 0;
  }
  h1 {
    font-size: 1.5rem;
  }
  h2 {
    font-size: 1.2rem;
  }
  h3 {
    font-size: 1rem;
  }
  a {
    text-decoration: none;
  }
.row {
    display: flex;
    justify-content: space-between;
  }
  .row.center {
    align-items: center;
  }
  .col-1 {
    flex: 1 1;
  }
  .col-2 {
    flex: 2 1;
  }
  .block {
    background-color: #fff;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 0.5rem;
  }
  .text-right {
    text-align: right;
  }
  img.small {
    max-height: 8rem;
  }

.boldText {
  font-weight: bold;
}  
  button {
    font-size: 0.8rem;
    padding: 0.2rem;
    margin: 0.1rem;
    border: 0.01rem #404040 solid;
    background-color: #f0c040;
    width: 100%;
    cursor: pointer;
  }
  button.add {
    background-color: #000;
    color: #fff;
    width: 2rem;
  }
  button.remove {
    background-color: #fff;
    width: 2rem;
  }
  button.badge {
    background-color: #f04040;
    border: none;
    color: #ffffff;
    width: 1.5rem;
  }


  .nav-tabs .nav-link {
    background-color: transparent !important;
    color: #444 !important;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    font-size: 0.875rem;
  }
  
  .nav-tabs .nav-link.active {
    border-bottom: solid 4px #000 !important;
    color: #000 !important;
    font-size: 0.875rem;
    font-weight: 'bold';
  }
  .card {
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    padding: 1.2rem;
    border: 1px #ccc solid;
    box-shadow: 1px 1px 1px 0 rgba(192, 192, 192, 0.3), 0 1px 2px 0 rgba(192, 192, 192, 0.2);
    position: relative;
  }    
    .cardContent {
      padding: 0.4rem 0;
    }

    .cardContent h3 {
        font-weight: 600;
        font-size: 0.9rem;
        margin-bottom: 0;
        letter-spacing: 0.6px;
      }
    .cardContent span {
        font-size: 0.8rem;
        color: #282828;
      }
      .bg {
        background: #000;
      
    height: '100%';
    
    overflow: 'hidden';
      }

/*   color variables 
$clr-primary: #0e48fe;
$clr-primary-hover: #29e6a7;
$clr-primary-dark: #039d69;
$clr-gray100: #f9fbff;
$clr-gray150: #f4f6fb;
$clr-gray200: #eef1f6;
$clr-gray300: #e1e5ee;
$clr-gray400: #767b91;
$clr-gray500: #4f546c;
$clr-gray600: #2a324b;
$clr-gray700: #161d34;
*/


body {
  font-family: "Noto Sans", sans-serif;
  color: #4f546c;
}

.wrapper {
  margin: 2rem;
  display: grid;
  grid-gap: 1rem;
  gap: 1rem;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));

}

.card {
  box-shadow: 0 0 10px 5px #e1e5ee;
  border-radius: 0.2rem;
  display: flex;
  min-width: 14rem;
}

.cardImg {
    width: 14rem;
    height: 10rem;
    object-fit: cover;
}

.cardBody {
    margin: 1rem;
    flex-grow: 1;
}
.cardTitle {
    font-size: 1rem;
    line-height: 1.4rem;
    margin-bottom: 0.5rem;
}
.cardDescription {
    line-height: 1.2rem;
    font-size: 0.8rem;
}
.cardPrice {
    font-size: 0.9rem;
    margin-top: 0.6rem;
      font-size: 1rem;
      position: relative;
      top: -0.3rem;
      padding-right: 0.1rem;
}

.cardBtn {
    border: none;
    border-top: 1px solid #e1e5ee;
    background-color: transparent;
    font-family: inherit;
    font-size: 0.8rem;
    font-weight: bold;
    color: inherit;
    width: 100%;
    padding-top: 1rem;
    margin-top: 1rem;
    cursor: pointer;
}
.cardBtn:hover {
      color: #0e48fe;
}


@media screen and (max-width: 992px) {
  .wrapper {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));
  }

  .card {
    flex-direction: column;
    text-align: center;
    min-width: 14rem;
  }
  .cardImg {
      width: 100%;
      height: 10rem;
    }
}


@media screen and (max-width: 600px) {
  .wrapper {
    grid-template-columns: repeat(auto-fit, minmax(14rem, 16rem));
  }

  .card {
    flex-direction: column;
    text-align: center;
    min-width: 14rem;
  }
  .cardImg {
      width: 100%;
      height: 10rem;
    }
  
}
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
}


.container{
  text-align: center;
  margin: 0 auto;
  width:30%;
  margin-top: 50px;
}


.container1{
  text-align: center;
  margin: 0 auto;
  width:90%;
  margin-top: 50px;
}

.one{
  background:#FFEB3B;
  width:"50%";
  float:left;
}
.two{
  width:"50%";
  background:#8BC34A;
  overflow:hidden;
}

.css-fix {
  white-space: pre-wrap;
}

.blackLink {
  color: #444;
}
.blackLink:hover {
   color: #444
}

.whiteLink {
  color: #fff;
}
.whiteLink:hover {
   color: #fff
}

